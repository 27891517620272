import { listVideoTutorial } from '@/api/api.js';

// --------------------- STATE -----------------------------

export const state = {
  videoTutorial: []
};

// --------------------- MUTATIONS ----------------------------

export const mutations = {
  PUSH_VIDEO_TUTORIAL(state, videos) {
    state.videoTutorial = [];

    if (videos.length > 0) {
      state.videoTutorial.push(...videos);
    }
  }
};

export const actions = {
  async storeListVideoTutorial({ dispatch, commit }, data) {
    try {
      const video = await listVideoTutorial(data);
      commit('PUSH_VIDEO_TUTORIAL', video.data.listVideoTutorial);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeListVideoTutorial',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  }
};

export const getters = {};
