import {
  createGlobalLayer,
  editGlobalLayer,
  getCustomerLayer,
  deleteCustomerLayer
} from '@/api/api.js';
import 'ol/ol.css';
import AWS from 'aws-sdk';
import Map from 'ol/Map';
import View from 'ol/View';
import { Vector as VectorSource } from 'ol/source';
import OSM from 'ol/source/OSM';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import GeoJSON from 'ol/format/GeoJSON';
import KMLSource from 'ol/format/KML';
import { Circle, Fill, Style } from 'ol/style';
import Stroke from 'ol/style/Stroke';
import shp from 'shpjs';

export const state = {
  linkHeaders: [
    {
      text: 'Layer Name',
      value: 'layer_name'
    },
    {
      text: 'Layer Description',
      value: 'layer_description'
    }
  ],
  customerLayers: [],
  selectedGlobalLayer: [],
  styles: [],
  layer_name: '',
  layer_description: '',
  layer_polygon: '',
  layer_type: '',
  color: '',
  width: null,
  showCreateLayer: false,
  storeLayerForm: {},
  storeTranslationLayerForm: {
    layer_name: '',
    layer_description: ''
  },
  isEditing: false,
  isChangingFile: false,
  uploaded: false,
  overlay: false,
  map: null,
  GeoJSON: new GeoJSON(),
  fileToEdit: null,
  fileChoice: 'geojson',
  raster: [new TileLayer({
    source: new OSM()
  })],
  uploadKML: false,
  downloadKML: '',
  renderFileMap: false,
  vector: null
};

export const mutations = {
  PUSH_GLOBAL_LAYER(state, layer) {
    state.globalLayers = [];
    state.globalLayers.push(layer);
  },
  GET_CUSTOMER_LAYER(state, layersArray) {
    state.customerLayers = [];
    state.customerLayers = layersArray;
  },
  STORE_OVERLAY(state, overlay) {
    state.overlay = overlay;
  },
  setUploadKML(state, upload_state) {
    state.uploadKML = upload_state;
  }
};

export const actions = {
  overlayAction({ commit }, overlay) {
    commit('STORE_OVERLAY', overlay);
  },
  async createGlobalLayerAction({ dispatch, commit }, data) {
    try {
      const layer = await createGlobalLayer(data);
      commit('PUSH_GLOBAL_LAYER', layer.data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Layer created',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'createGlobalLayerAction',
          errObj: {
            errors: [{ message: 'Error creating Layer.' }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async editGlobalLayerAction({ dispatch }, data) {
    try {
      await editGlobalLayer(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Layer edited',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'editGlobalLayerAction',
          errObj: {
            errors: [{ message: 'Error editing Layer.' }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeCustomerLayers({ dispatch, commit }, crm_id) {
    try {
      const dbLayers = await getCustomerLayer(crm_id);
      commit('GET_CUSTOMER_LAYER', dbLayers.data.getCustomerLayer.sort((a, b) => (a.layer_name.toUpperCase() > b.layer_name.toUpperCase() ? 1 : -1)));
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeCustomerLayers',
          errObj: {
            errors: [{ message: 'Errore imprevisto. Riprovare o contattare il supporto.' }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async deleteCustomerLayerAction({ dispatch }, data) {
    try {
      await deleteCustomerLayer(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Customer Layer deleted',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'deleteGlobalLayerAction',
          errObj: {
            errors: [{ message: 'Error deleting layer.' }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async startMap({ state, dispatch }) {
    try {
      state.map = new Map({
        layers: state.raster,
        target: 'map',
        view: new View({
          projection: 'EPSG:4326',
          center: [12.002134, 42.423457],
          zoom: 5.7
        })
      });
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'startMap Error',
          errObj: {
            errors: [{ message: 'Errore imprevisto. Riprovare o contattare il supporto' }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async setLayerGeojson({ state, dispatch }, data) {
    dispatch('setStylesArray', data);

    try {
      const source = new VectorSource({ wrapX: false });
      state.vector = new VectorLayer({
        source: source,
        style: state.styles
      });
      state.map.addLayer(state.vector);
      source.addFeatures(state.GeoJSON.readFeatures(state.layer_polygon));
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'setLayerGeojson',
          errObj: {
            errors: [{ message: 'Error. Try again or contact support.' }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async getGEOJSON({ state, dispatch }, data) {
    const [obj, color, width] = data;
    dispatch('setStylesArray', [color, width]);
    const s3_bucket = new AWS.S3();
    const response = await s3_bucket.getSignedUrlPromise('getObject', obj);

    if (response) {
      const source = new VectorSource({
        format: new GeoJSON(),
        url: response
      });
      state.vector = new VectorLayer({
        source: source,
        style: state.styles
      });
      await state.map.addLayer(state.vector);
    } else {
      dispatch(
        'storeToastMessage',
        {
          text: 'Error Link failed',
          type: 'error'
        },
        { root: true }
      );
    }
  },
  async setUpKML({ dispatch, commit }, data) {
    const [params, obj, name, color, width] = data;
    const upload = new AWS.S3.ManagedUpload({
      params,
      leavePartsOnError: true
    });

    try {
      state.overlay = true;
      upload.send(async function(err, uploaded) {
        if (uploaded.Bucket.includes('-')) {
          await commit('setUploadKML', true);

          if (name.includes('.kml')) {
            await dispatch('getKML', [obj, color, width]);
          } else {
            await dispatch('getSHP', [obj, color, width]);
          }
        }

        if (err) {
          await commit('setUploadKML', false);
          dispatch(
            'storeToastMessage',
            {
              text: 'Error Link failed',
              type: 'error'
            },
            { root: true }
          );
        }
      });
    } catch {
      await commit('setUploadKML', false);
      dispatch(
        'storeToastMessage',
        {
          text: 'Error Link failed',
          type: 'error'
        },
        { root: true }
      );
    }
  },
  async getKML({ dispatch, state }, data) {
    const [obj, color, width] = data;
    dispatch('setStylesArray', [color, width]);
    state.overlay = true;
    const s3_bucket = new AWS.S3();
    const response = await s3_bucket.getSignedUrlPromise('getObject', obj);

    if (response) {
      state.vector = new VectorLayer({
        source: new VectorSource({
          url: response,
          format: new KMLSource({
            extractStyles: false
          })
        }),
        style: state.styles
      });
      state.map.addLayer(state.vector);
      state.overlay = false;
    } else {
      dispatch(
        'storeToastMessage',
        {
          text: 'Error Link failed',
          type: 'error'
        },
        { root: true }
      );
    }
  },
  async getSHP({ state, dispatch }, data) {
    const [obj, color, width] = data;
    dispatch('setStylesArray', [color, width]);
    state.overlay = true;
    const s3_bucket = new AWS.S3();
    const response = await s3_bucket.getSignedUrlPromise('getObject', obj);

    if (response) {
      shp(response).then(async function(geojson) {
        const source = new VectorSource({ wrapX: false });
        state.vector = new VectorLayer({
          source: source,
          style: state.styles
        });

        await state.map.addLayer(state.vector);
        await source.addFeatures(state.GeoJSON.readFeatures(geojson));

        state.overlay = false;
      });
    } else {
      dispatch(
        'storeToastMessage',
        {
          text: 'Error Link failed',
          type: 'error'
        },
        { root: true }
      );
    }
  },
  setStylesArray({ state }, data) {
    const [color, width] = data;
    state.styles = [new Style({
      image: new Circle({
        radius: width,
        fill: new Fill({ color: color }),
        stroke: new Stroke({ color: color, width: 1 })
      })
    }),
    new Style({
      stroke: new Stroke({
        color: color,
        width: width
      })
    })];
  }
};
