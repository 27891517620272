<template>
  <div>
    <v-flex
      offset-lg1
      class="flex-margin"
    >
      <v-card>
        <v-card-title
          class="card-title v-card-scroll"
          color="black"
        >
          {{ $t('VideoCourses.title') }}
          <v-spacer />
          <v-icon
            v-if="mobileBreakpoints()"
            color="red"
            size="28"
            @click="$router.push('/')"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <div class="video-list">
          <div
            v-for="video in sortedVideos"
            :key="video.video_id"
            :class="mobileBreakpoints() ? 'video-item-mobile' : 'video-item'"
          >
            <div :class="mobileBreakpoints() ? 'video-container-mobile' : 'video-container'">
              <iframe
                :key="video.video_id"
                :src="video.url"
                frameborder="0"
                allowfullscreen
                referrerpolicy="strict-origin-when-cross-origin"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              />
            </div>
            <div :class="mobileBreakpoints() ? 'video-info-mobile' : 'video-info'">
              <p class="video-title">
                {{ $t('VideoCourses.'+video.video_id+'.title') }}
              </p>
              <p class="video-description">
                {{ $t($t('VideoCourses.'+video.video_id+'.description')) }}
              </p>
            </div>
          </div>
        </div>
      </v-card>
    </v-flex>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(['video_tutorial']),
    sortedVideos() {
      return [...this.video_tutorial.videoTutorial].sort((a, b) => a.sort_id - b.sort_id);
    }
  },
  async mounted() {
    await this.storeListVideoTutorial();
  },
  methods: {
    ...mapActions(['storeListVideoTutorial']),
    isLandscape() {
      return /Mobi|Android/i.test(navigator.userAgent) && window.innerHeight < window.innerWidth;
    },
    mobileBreakpoints() {
      return /Mobi|Android/i.test(navigator.userAgent) || this.isLandscape();
    }
  }
};
</script>

<style scoped>
.flex-margin {
  margin-right: 2%;
  margin-top: 2%
}

.card-title {
  color: #0da344;
  background-color: #f4f3f3;
  font-size: 22px;
  font-weight: 500;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
}

.video-list {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.video-item {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.video-item-mobile {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
}

.video-container {
  position: relative;
  width: 50%; /* Puoi cambiare questa percentuale per adattarla */
  padding-bottom: 28.125%; /* 40% * 9 / 16 per mantenere il rapporto 16:9 */
  height: 0;
  overflow: hidden;
}

.video-container-mobile {
  position: relative;
  width: 100%; /* Puoi cambiare questa percentuale per adattarla */
  padding-bottom: 56.25%; /* 40% * 9 / 16 per mantenere il rapporto 16:9 */
  height: 0;
  overflow: hidden;
}

.video-container iframe, .video-container-mobile iframe {
  border-radius: 24px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.video-info {
  margin-top: 20px;
  flex: 1;
}

.video-info-mobile {
  flex: 1;
}

.video-title {
  color: #0da344;
  font-size: 20px;
  font-weight: 700;
}

.video-description {
  margin-top: -12px;
  color: grey;
}
</style>
