
import { getTicketingServiceConfiguration, createTicketingRequest, linkLocationToTicketingRequest, getTicketingRequest, deleteTicketingRequest, linkDocumentS3ToTicketingRequest, getLinkedDocumentS3toTicketingRequest, deleteDocumentS3, editTicketingRequest, listEvasionLimitSlots, listTicketingRequestLimit, listComuniProvince, listDocumentMeteoTypes } from '@/api/api.js';
import moment from 'moment-business-days';
import { categorizeError } from '@/utils.js';

export const state = {
  createRequestState: false,
  viewTicketingDetailPopup: false,
  app_user_ticket_id: '',
  request_id: '',
  headers: [
    {
      text: 'ServiceCard.startDate',
      value: 'start_date',
      sortable: false
    },
    {
      text: 'ServiceCard.endDate',
      value: 'end_date',
      sortable: false
    },
    {
      text: 'Ticketing.latitude',
      value: 'latitude',
      sortable: false
    },
    {
      text: 'Ticketing.longitude',
      value: 'longitude',
      sortable: false
    },
    {
      text: 'Ticketing.address',
      value: 'address',
      sortable: false
    },
    {
      text: 'Ticketing.meteoType',
      value: 'meteo_type',
      sortable: false
    },
    {
      text: 'TicketingTable.actions',
      value: 'actions',
      sortable: false
    }
  ],
  downloadS3HeadersAppUser: [{
    text: 'Ticketing.fileName',
    value: 'file_name',
    sortable: false
  },
  {
    text: 'TicketingTable.requestType',
    value: 'request_type',
    sortable: false
  },
  {
    text: 'TicketingTable.actions',
    value: 'actions',
    sortable: false
  }],
  downloadS3HeadersRadarMeteo: [
    {
      text: 'Ticketing.fileName',
      value: 'file_name',
      sortable: false
    },
    {
      text: 'TicketingTable.requestType',
      value: 'request_type',
      sortable: false
    },
    {
      text: 'TicketingTable.actions',
      value: 'actions',
      sortable: false
    }
  ],
  appUserPolicyTicketing: [],
  ticketingRequestLimit: [],
  comuniProvince: [],
  ticketingServiceConfiguration: [],
  ticketingRequestList: [],
  ticketingRequestListFormatted: [],
  storeTicketingRequestList: [],
  documentS3ItemsAppUser: [],
  documentS3ItemsRadarmeteo: [],
  storeDocumentS3Items: [],
  evasionLimitSlots: [],
  documentTypes: [],
  meteoTypes: [],
  searchFilters: {}
};

export const mutations = {
  GET_TICKETING_SERVICES_CONFIGURATION(state, ticketingServicesConfiguration) {
    state.ticketingServiceConfiguration = ticketingServicesConfiguration;
  },
  GET_LIST_COMUNI_PROVINCE(state, listComuniProvince) {
    state.comuniProvince = listComuniProvince;
  },
  GET_LIST_DOCUMENT_TYPES(state, listDocumentMeteoTypes) {
    state.documentTypes = listDocumentMeteoTypes;
  },
  GET_LIST_METEO_TYPES(state, listDocumentMeteoTypes) {
    state.meteoTypes = listDocumentMeteoTypes;
  },
  setCreateRequestState(state, createRequestState) {
    state.createRequestState = createRequestState;
  },
  setListEvasionLimitSlots(state, data) {
    state.evasionLimitSlots = data;
  },
  setTicketingRequest(state, getTicketingRequest) {
    state.ticketingRequestList = getTicketingRequest;
    state.ticketingRequestListFormatted = JSON.parse(JSON.stringify(getTicketingRequest));
    state.ticketingRequestListFormatted.forEach(item => {
      item.request_date = item.request_date ? moment(item.request_date).format('DD-MM-YYYY') : item.request_date;
      item.evasion_limit = item.evasion_limit ? moment(item.evasion_limit).format('DD-MM-YYYY') : item.evasion_limit;
      item.evasion_date = item.evasion_date ? moment(item.evasion_date).format('DD-MM-YYYY') : item.evasion_date;
      item.meteo_type = item.locations[0].meteo_type;

      // item.locations.forEach((e, i) => {
      //   if (i === 0) {
      //     item.meteo_type = e.meteo_type.split(',')[0];
      //   } else {
      //     item.meteo_type += `, ${e.meteo_type.split(',')[0]}`;
      //   }
      // });

      const tempArray = item.full_address.split(', ');
      tempArray.forEach((element, index) => {
        if (element !== '') {
          if (index === 0) {
            item.full_address = `${element}, `;
          } else {
            item.full_address += index % 3 !== 0
              ? `${parseFloat(element).toFixed(2)}${index % 3 === 1 ? ';' : ', '}`
              : `${element}, `;
          }
        }
      });

      item.locations.forEach(location => {
        location.latitude = parseFloat(location.latitude).toFixed(2);
        location.longitude = parseFloat(location.longitude).toFixed(2);
        location.end_date = location.end_date ? moment(location.end_date).format('DD-MM-YYYY') : location.end_date;
        location.start_date = location.start_date ? moment(location.start_date).format('DD-MM-YYYY') : location.start_date;
      });
    });
  },
  setTicketingRequestLimit(state, ticketingRequestLimit) {
    state.ticketingRequestLimit = ticketingRequestLimit;
  },
  setLinkedDocumentS3(state, getLinkedDocumentS3) {
    state.documentS3ItemsAppUser = [];
    state.documentS3ItemsRadarmeteo = [];
    getLinkedDocumentS3.forEach(item => {
      if (item.document_creator === 'app_user') {
        state.documentS3ItemsAppUser.push(item);
      }

      if (item.document_creator === 'radarmeteo') {
        state.documentS3ItemsRadarmeteo.push(item);
      }
    });
    state.storeDocumentS3Items = JSON.parse(JSON.stringify(getLinkedDocumentS3));
  }
};

// --------------- ACTIONS --------------------

export const actions = {
  async storeGetTicketingServiceConfiguration(
    { dispatch, commit },
    service_id
  ) {
    try {
      const dbTicketingServiceConfiguration = await getTicketingServiceConfiguration(
        service_id
      );
      commit(
        'GET_TICKETING_SERVICES_CONFIGURATION',
        dbTicketingServiceConfiguration.data.getTicketingServiceConfiguration
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeGetTicketingServiceConfiguration',
          errObj: {
            errors: [{ message: categorizeError(err) }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async createTicketingRequestAction({ commit, dispatch }, data) {
    try {
      await createTicketingRequest(data);
      commit('setCreateRequestState', true);
      dispatch(
        'storeToastMessage',
        {
          text: 'Ticketing request send',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      commit('setCreateRequestState', false);
      dispatch(
        'storeToastMessage',
        {
          text: 'createTicketingRequestAction',
          errObj: {
            errors: [{ message: categorizeError(err) }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async linkLocationToTicketingRequestAction({ dispatch }, data) {
    try {
      await linkLocationToTicketingRequest(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Location linked to request',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'linkLocationToTicketingRequestAction',
          errObj: {
            errors: [{ message: categorizeError(err) }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeListEvasionLimitSlots({ commit, dispatch }, data) {
    try {
      const resp = await listEvasionLimitSlots(data);
      commit('setListEvasionLimitSlots', resp.data.listEvasionLimitSlots);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeListEvasionLimitSlots',
          errObj: {
            errors: [{ message: categorizeError(err) }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async reorganizeSlots({ dispatch, state }, evasion_limit) {
    if (evasion_limit !== '') {
      const datesToCheck = [...Array(2)].map((e, i) => moment().add(i, 'years').format('YYYY-'));
      await dispatch('storeListEvasionLimitSlots', datesToCheck);

      const next15Slots = [...Array(15)].map((e, i) => moment(evasion_limit).businessAdd(i + 1).format('YYYY-MM-DD'));
      const filteredSlots = state.evasionLimitSlots.filter(d => next15Slots.includes(d.evasion_limit));
      const slotsToMove = [];

      for (const slot of filteredSlots) {
        const checkCanBeMoved = slot.can_be_moved > 0;
        const checkFreeSlots = slot.total_slots < slot.max_slots;
        const checkExceededSlots = slot.total_slots > slot.max_slots;
        const checkZeroSlots = slot.total_slots === 0 && slot.max_slots === 0;

        if (checkZeroSlots) {
          continue;
        }

        if (checkCanBeMoved) {
          slotsToMove.push(slot.evasion_limit);
        }

        if (!checkCanBeMoved || checkFreeSlots || checkExceededSlots) {
          break;
        }
      }

      return slotsToMove;
    }

    return [];
  },
  async storeGetTicketingRequest({ commit, dispatch, state }, data) {
    const filtersData = data.map(e => ({ ...e, filters: { ...state.searchFilters } }));

    try {
      const resp = await getTicketingRequest(filtersData);
      commit('setTicketingRequest', resp.data.getTicketingRequest);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeGetTicketingRequest',
          errObj: {
            errors: [{ message: categorizeError(err) }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async deleteTicketingRequestAction({ dispatch, commit }, data) {
    try {
      await deleteTicketingRequest(data);
      commit('setCreateRequestState', true);
      dispatch(
        'storeToastMessage',
        {
          text: 'Ticketing request canceled',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      commit('setCreateRequestState', false);
      dispatch(
        'storeToastMessage',
        {
          text: 'deleteTicketingRequestAction',
          errObj: {
            errors: [{ message: categorizeError(err) }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async linkDocumentS3ToTicketingRequestAction({ dispatch }, data) {
    try {
      await linkDocumentS3ToTicketingRequest(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Document S3 linked to request',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'linkDocumentS3ToTicketingRequestAction',
          errObj: {
            errors: [{ message: categorizeError(err) }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeGetLinkedDocumentS3toTicketingRequest({ commit, dispatch }, data) {
    try {
      const resp = await getLinkedDocumentS3toTicketingRequest(data);
      commit('setLinkedDocumentS3', resp.data.getLinkedDocumentS3toTicketingRequest);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeGetLinkedDocumentS3toTicketingRequest',
          errObj: {
            errors: [{ message: categorizeError(err) }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async deleteDocumentS3Action({ dispatch }, data) {
    try {
      await deleteDocumentS3(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Document S3 deleted from DB',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'deleteDocumentS3',
          errObj: {
            errors: [{ message: categorizeError(err) }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async editTicketingRequestAction({ commit, dispatch }, data) {
    try {
      await editTicketingRequest(data);
      commit('setCreateRequestState', true);
      dispatch(
        'storeToastMessage',
        {
          text: 'Ticketing request edited',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      commit('setCreateRequestState', false);
      dispatch(
        'storeToastMessage',
        {
          text: 'editTicketingRequestAction',
          errObj: {
            errors: [{ message: categorizeError(err) }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async listTicketingRequestLimitAction({ commit, dispatch }, data) {
    try {
      const resp = await listTicketingRequestLimit([data]);
      commit('setTicketingRequestLimit', resp.data.listTicketingRequestLimit);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'listTicketingRequestLimit',
          errObj: {
            errors: [{ message: categorizeError(err) }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async listComuniProvinceAction({ commit, dispatch }, is_prov) {
    try {
      const resp = await listComuniProvince(is_prov);
      commit('GET_LIST_COMUNI_PROVINCE', resp.data.listComuniProvince.list);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'listComuniProvinceAction',
          errObj: {
            errors: [{ message: categorizeError(err) }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async listDocumentMeteoTypesAction({ commit, dispatch }, type) {
    try {
      const resp = await listDocumentMeteoTypes(type);

      if (type === 'document') {
        commit('GET_LIST_DOCUMENT_TYPES', resp.data.listDocumentMeteoTypes.list);
      }

      if (type === 'meteo') {
        commit('GET_LIST_METEO_TYPES', resp.data.listDocumentMeteoTypes.list);
      }
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: `listDocumentMeteoTypesAction: ${type}`,
          errObj: {
            errors: [{ message: categorizeError(err) }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  }
};

