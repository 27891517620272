import { getAppUserPolicy } from '@/api/api.js';

export const state = {
  wsAppUserPolicy: []
};

export const mutations = {
  PUSH_WS_SERVICE(state, data) {
    state.wsAppUserPolicy = [];
    state.wsAppUserPolicy = data;
  }
};

export const actions = {
  async storeWsServices({ commit, dispatch }, data) {
    try {
      const wsService = await getAppUserPolicy(data);
      commit('PUSH_WS_SERVICE', wsService.data.listAppUserPolicy[0].WS);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeWsServices',
          errObj: {
            errors: [{ message: 'Errore imprevisto. Riprovare o contattare il supporto.' }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  }
};

export const getters = {};
