import Vue from 'vue';
import Vuex from 'vuex';
import * as map from '@/store/modules/map';
import * as geoserver_data from '@/store/modules/geoserver_data';
import * as drawer from '@/store/modules/drawer';
import * as policy from '@/store/modules/policy';
import * as file_transfer from '@/store/modules/file_transfer';
import * as wsServices from '@/store/modules/wsServices';
import * as ticketing from '@/store/modules/ticketing';
import * as app_user from '@/store/modules/app_user';
import * as table_chart from '@/store/modules/table_chart';
import * as global_layers from '@/store/modules/global_layers';
import * as other_services from '@/store/modules/other_services';
import * as video_tutorial from '@/store/modules/video_tutorial';

import { createModule, ADD_TOAST_MESSAGE } from 'vuex-toast';
import { getErrorMessage, log } from '@/constants.js';

const DISMISS_AFTER = 6000;

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    map,
    geoserver_data,
    toast: createModule({
      dismissInterval: DISMISS_AFTER
    }),
    drawer,
    policy,
    file_transfer,
    wsServices,
    ticketing,
    app_user,
    table_chart,
    global_layers,
    other_services,
    video_tutorial
  },
  state: {
    username: ''
  },
  mutations: {
    USERNAME(state, u) {
      state.username = u;
    }
  },
  actions: {
    storeUsername({ commit }, username) {
      commit('USERNAME', username);
    },
    storeToastMessage({ dispatch }, error) {
      log.error(error.text);
      const err = error.errObj ? getErrorMessage(error.errObj) : error.text;
      log.error(error);
      dispatch(ADD_TOAST_MESSAGE, {
        text: err,
        type: error.type
      });
    }
  },
  getters: {}
});
