import {
  getWorkspaces,
  getWSLayers,
  getLayers,
  getLayerPerName,
  getLegendValues,
  getWmtsDescribeDomains,
  getWmtsGetDomainValues,
  getCapabilities
} from '@/api/services';
import {
  getDBLayers
} from '@/api/api.js';
import { transformExtent } from 'ol/proj';

// --------------------- STATE -----------------------------

export const state = {
  geoserverWorkspaces: [],
  geoserverLayers: [],
  allLayers: [],
  legendArray: [],
  rasterLegend_selectedLabel: [],
  vectorLegend_selectedLabel: {},
  tmpTarghetLayer: {},
  geographicDomains: {},
  layer: null,
  isNewData: [],
  isRuntime: false,
  getWmtsStatus: false,
  xml_string: '',
  xml_string_GDV: ''
};

// --------------------- MUTATIONS ----------------------------

export const mutations = {
  // GEOSERVER
  GET_WORKSPACES(state, workspacesArray) {
    state.geoserverWorkspaces = workspacesArray;
  },

  GET_LAYERS(state, layersArray) {
    state.geoserverLayers = [];

    if (layersArray.length > 0) {
      layersArray.forEach(element => {
        state.geoserverLayers.push(element.name);
      });
    }
  },
  SET_ALL_LAYERS(state, data) {
    state.allLayers = data;
  },
  SET_LAYER(state, data) {
    state.layer = data;
  },
  SET_WMTS_RESP(state, data) {
    state.xml_string = data;
  },
  SET_WMTS_GDV_RESP(state, data) {
    state.xml_string_GDV = data;
  },
  SET_CHECK_NEW_DATA_RESP(state, data) {
    state.isNewData = data;
  },
  SET_CHECK_RUNTIME_DATA_RESP(state, data) {
    state.isRuntime = data;
  },
  SET_GEOGRAPHIC_DOMAIN(state, data) {
    const [service_id, extent] = data;
    state.geographicDomains[service_id] = [];
    state.geographicDomains[service_id] = extent;
  }

};

// --------------- FUNCTIONS --------------------

function categorizeError(err) {
  if (err.code === 'ECONNABORTED') { // Timeout della richiesta
    return 'Richiesta scaduta. Ridurre l\'intervallo e riprovare.';
  } else if (err.code === 'ENOTFOUND' || err.code === 'ETIMEDOUT') { // Problemi di rete lato client
    return 'Connessione assente. Verificare la rete e riprovare.';
  } else if (err.response === undefined && err.request) { // Problemi di rete lato server
    return 'Errore di rete. Riprovare o contattare il supporto.';
  } else { // Errore generico
    return 'Errore imprevisto. Contattare il supporto.';
  }
}


// ------------------------ ACTIONS ---------------------------

export const actions = {
  // GEOSERVER
  async storeWorkspaces({ dispatch, commit }) {
    try {
      const resp = await getWorkspaces();
      commit('GET_WORKSPACES', resp.data.workspaces.workspace);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeWorkspaces',
          errObj: {
            errors: [{ message: categorizeError(err) }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async storeWSLayers({ dispatch, commit }, workspace) {
    try {
      const resp = await getWSLayers(workspace);
      commit('GET_LAYERS', resp.data.layers.layer);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeWSLayers',
          errObj: {
            errors: [{ message: categorizeError(err) }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async storeLayers({ dispatch, commit }) {
    try {
      const resp = await getLayers();
      commit('SET_ALL_LAYERS', resp.data.layers.layer);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeLayers',
          errObj: {
            errors: [{ message: categorizeError(err) }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async storeLayerPerName({ dispatch, commit }, data) {
    try {
      const resp = await getLayerPerName(data);
      commit('SET_LAYER', resp.data.layer);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeLayerPerName',
          errObj: {
            errors: [{ message: categorizeError(err) }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async storeDBLayers({ rootState, state, dispatch, commit }) {
    try {
      const layersArray = await getDBLayers(
        state.workspace.workspace_name,
        rootState.customerCRMID.policy_id
      );
      commit('GET_DB_LAYERS', layersArray.data.listDBLayers);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeDBLayers',
          errObj: {
            errors: [{ message: categorizeError(err) }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async getLegend({ state, dispatch }, data) {
    try {
      state.legendArray = await getLegendValues(data.layer_name, data.layer_style);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'getLegend',
          errObj: {
            errors: [{ message: 'Error while fetching legend. Reload the layer.' }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async getWmtsArray({ commit, dispatch }, data) {
    try {
      const { layer, time, service_id, policy_id } = data;
      const resp = await getWmtsDescribeDomains(layer, time, service_id, policy_id);
      commit('SET_WMTS_RESP', resp.data);
      state.getWmtsStatus = true;
    } catch (err) {
      state.getWmtsStatus = false;
      dispatch(
        'storeToastMessage',
        {
          text: 'getWmtsArray',
          errObj: {
            errors: [{ message: categorizeError(err) }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async getWmtsGDV({ commit, dispatch }, data) {
    try {
      const [layer, time, limit] = data;
      const resp = await getWmtsGetDomainValues('time', layer, time, limit);
      commit('SET_WMTS_GDV_RESP', resp.data);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'getWmtsGDV',
          errObj: {
            errors: [{ message: categorizeError(err) }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async checkNewData({ commit, dispatch }, data) {
    try {
      const [domain, layer, time] = data;
      const resp = await getWmtsGetDomainValues(domain, layer, time, 1);

      const xml_document = (new window.DOMParser()).parseFromString(resp.data, 'text/xml');
      const xml_content = xml_document.getElementsByTagName('Domain')[0].textContent;
      const wmtsResultArray = xml_content ? xml_content.split(',') : [];

      if (domain === 'time') {
        commit('SET_CHECK_NEW_DATA_RESP', [wmtsResultArray.length > 0, wmtsResultArray[0]]);
      }

      if (domain === 'RUNTIME') {
        commit('SET_CHECK_RUNTIME_DATA_RESP', wmtsResultArray.length > 0);

        return wmtsResultArray[0] || time;
      }
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'checkNewData',
          errObj: {
            errors: [{ message: 'Error while checking new data. Reload the layer.' }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async getLayerExtent({ commit, dispatch }, data) {
    const transform = (extent) => {
      return transformExtent(extent, 'EPSG:4326', 'EPSG:3857');
    };

    try {
      const extent = [];
      const [ws, layer, type, service_id] = data;
      const resp = await getCapabilities(ws, layer, type);

      const xml_document = (new window.DOMParser()).parseFromString(resp.data, 'text/xml');
      const xml_for_vector = xml_document.getElementsByTagName('FeatureType');

      if (type === 'VECTOR') {
        xml_for_vector.forEach(item => {
          if (item.getElementsByTagName('Name')[0].innerHTML === `${ws}:${layer}`) {
            const extentNode = item.getElementsByTagName('ows:WGS84BoundingBox')[0].children;

            extentNode.forEach(element => {
              extent.push(parseFloat(element.textContent.split(' ')[0]));
              extent.push(parseFloat(element.textContent.split(' ')[1]));
            });
          }
        });
      } else {
        extent.push(parseFloat(xml_document.getElementsByTagName('westBoundLongitude')[0].textContent));
        extent.push(parseFloat(xml_document.getElementsByTagName('southBoundLatitude')[0].textContent));
        extent.push(parseFloat(xml_document.getElementsByTagName('eastBoundLongitude')[0].textContent));
        extent.push(parseFloat(xml_document.getElementsByTagName('northBoundLatitude')[0].textContent));
      }

      commit('SET_GEOGRAPHIC_DOMAIN', [service_id, transform(extent)]);

      return transform(extent);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'getLayerExtent',
          errObj: {
            errors: [{ message: categorizeError(err) }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  }
};

export const getters = {};
