import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import Services from '@/views/Services.vue';
import TicketingRequestList from '@/views/TicketingRequestList.vue';
import Registry from '@/components/settings/Registry.vue';
import Contacts from '@/components/settings/Contacts.vue';
import VideoCourses from '@/components/settings/VideoCourses.vue';
import Disclaimer from '@/components/settings/Disclaimer.vue';
import Manuals from '@/components/utils/Manuals.vue';
import CustomerLayers from '@/components/settings/CustomerLayers.vue';
import Reports from '@/views/Reports.vue';
import ReportHub from '@/views/ReportHub.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/map/:id',
    name: 'Map',
    component: Home
  },
  {
    path: '/services/:id',
    name: 'Services',
    component: Services
  },
  {
    path: '/ticketing-request/:id',
    name: 'TicketingRequest',
    component: Home
  },
  {
    path: '/ticketing-table/:id',
    name: 'TicketingRequestList',
    component: TicketingRequestList
  },
  {
    path: '/settings',
    name: 'Registry',
    component: Registry
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts
  },
  {
    path: '/video-courses',
    name: 'Video-Courses',
    component: VideoCourses
  },
  {
    path: '/disclaimer',
    name: 'Disclaimer',
    component: Disclaimer
  },
  {
    path: '/disclaimer-meteotrigger',
    name: 'Disclaimer Meteotrigger',
    component: Disclaimer
  },
  {
    path: '/manuals',
    name: 'Manuals',
    component: Manuals
  },
  {
    path: '/layers',
    name: 'CustomerLayers',
    component: CustomerLayers
  },
  {
    path: '/alert-ex-post',
    name: 'Alert Ex-Post',
    component: Home
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports
  },
  {
    path: '/service/:id',
    name: 'Report-Hub',
    component: ReportHub
  }
];

const router = new VueRouter({
  routes
});
router.replace({ path: '', redirect: '/' });

export default router;
